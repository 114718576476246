import React from 'react'
import Helmet from 'react-helmet'

import Layout from "../../components/layout"
import BgImage from '../../components/bg-image'

const Pavers =  () => (
  <Layout>
    <Helmet title="Paving" />
    <BgImage image="/assets/pool-surrounds.jpg" className="top">
      <h1>Paving</h1>
    </BgImage>
    <section className="content">
      <p>
        Pool pavers and coping are the finishing touch to any pool that make it
        visibly different from other swimming pools. Our expert team will help
        you to find the perfect finish to contrast nicely with the surface of
        the pool. Pavers and coping are also essential for creating a link
        between the wet and dry as well as the hard and soft landscaping in your
        backyard.
      </p>
      <p>
        We can offer are number of different solutions depending on your needs
        from pool coping tiles to natural rock pavers. Most people choose either
        cobblestones, granite, slate, or sandstone pavers or coping. It is
        extremely important that the pavers and coping match the equipment to
        ensure the longevity of both. Speak to us about the following solutions:
      </p>
      <ul>
        <li>Granite</li>
        <li>Travertine</li>
        <li>Sandstone</li>
        <li>Quartzite</li>
        <li>Brick Pavers</li>
      </ul>
    </section>
  </Layout>
)

export default Pavers
